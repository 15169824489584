import { version } from '$app/environment';
import { PUBLIC_APP_ENV, PUBLIC_SENTRY_DSN } from '$env/static/public';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

const onError = init(PUBLIC_SENTRY_DSN, {
	sentryOptions: {
		environment: PUBLIC_APP_ENV,
		release: version,
		tracesSampleRate: 1.0
	},
	enableInDevMode: false
});

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const handleError = onError((e, sentryEventId) => {
	// noop
});
