import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13')
];

export const server_loads = [0];

export const dictionary = {
		"/": [~2],
		"/[locale=locale]": [3],
		"/[locale=locale]/account": [~4],
		"/[locale=locale]/account/auth-code-error": [5],
		"/[locale=locale]/account/data": [~6],
		"/[locale=locale]/account/profile": [~7],
		"/[locale=locale]/account/sign-in": [~8],
		"/[locale=locale]/chats": [9],
		"/[locale=locale]/chats/[chatId]": [~10],
		"/[locale=locale]/chats/[chatId]/message-sets/[messageSetId]": [11],
		"/[locale=locale]/chats/[chatId]/message-sets/[messageSetId]/[messageId]": [12],
		"/[locale=locale]/dashboard": [~13]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';